@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Playwrite+BE+VLG:wght@100..400&display=swap');
.playwrite-be-vlg-pink {
  font-family: "Playwrite BE VLG", cursive;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color:#AA336A;
}
.dmsans {
  font-family: "DM Sans", sans-serif;
  font-weight: 550;
  font-style: normal;
}

.dilutedpink{
  background-color: #F8E1E8;
}

.dilutedpinktext{
  color: #F3BBE0;
}

.white{
  background-image: url('./media/white.jpg');
}

.hamburger .div2{
  display:none;
}
.hamburger{
  gap:10px;
  transform: translateX(3px);
}
.hamburger .div1{
  rotate:45deg;
  transform: translateY(9px)
}

.hamburger .div3{
  rotate:-45deg;
  transform: translateY(-8px)

}